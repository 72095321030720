import React from "react";
import { setGenericSessionStorage } from "./utils/localSave";

//App mode
import RecruiterApp from "./recruiter";
import CandidateApp from "./candidate";

const App = () => {
  
  if (document.referrer) {
    setGenericSessionStorage("app_referrer", document.referrer);
  }

  //one repo in two places!
  const appMode = process.env.REACT_APP_MODE;

  if (appMode === "candidate") {
    return <CandidateApp />;
  } else if (appMode === "recruiter") {
    return <RecruiterApp />;
  } else {
    return <p>Missing app mode .env variable</p>;
  }
};

export default App;
