import styled from 'styled-components/macro';
import { NavLink as BaseNavLink } from "react-router-dom";


export const StyledSideMenu = styled.div`
  width: 100%; 
  height: 50px; 
  bottom: 0;
  left: 0; 
  position: absolute; 
  background-color: ${(props) => props.theme.colors.faceliftBlue};
  z-index: 99999;
  .nav_brand {
    display: none; 
  }
  & > a:first-child > div {
    padding: 50px;
  }
  @media (min-width: 1200px) {
    width: 240px; 
    height: 100%;
    top: 0; 
    position: relative;
    .nav_brand {
      display: flex; 
      margin-top: 50px; 
      margin-bottom: 50px;
    }
    .nav_icon {
      width: 50px; 
    }
  }
`;

export const NavLink = styled(BaseNavLink)`
.create_position {
  display: none; 
}
@media (min-width: 1200px) {
  .create_position_link {
    width: fit-content
  }
  .create_position {
    display: flex;
    position: absolute; 
    top: calc(50% - 8px); 
    right: 15px;
    opacity: 0; 
    z-index: 99999;
    pointer-events: all;
  }
  &:hover .create_position {
    opacity: .5;
    transition: .2s ease;
    &:hover {
      opacity: 1; 
      transition: .2s ease;
    }
  }
${(props) => props.active === 'true' ? 'animation: active .5s; background-color: rgba(255, 255, 255, 0.06);' : 'color: rgba(255, 255, 255, 0.5) !important;'}
position: relative; 
@keyframes active {
  0% {
    color: rgba(255, 255, 255, 0.4);
  }
  100% {
    color: rgba(255, 255, 255, 1); 
  }
}
}
${(props) => props.initiallyActive ? 'background-color: black !important;' : null};

${(props) => props.hascreatebutton ? `
  &:hover {
    &::after {
      content: '';
      position: absolute;
      height: 32px; 
      width: 1px; 
      background-color: rgba(255, 255, 255, .2);
      right: 45px;
      top: calc(50% - 16px);
      pointer-events: none;
    }
  }
` : null}

`;

export const MenuContainer = styled.div`
display: flex; 
justify-content: space-between;
align-items: flex-end;
height: 100%; 
padding: 0 20px; 
padding-bottom: 3px; 
height: 100%; 
ul {
  &:nth-of-type(2) {
    padding-right: 5px; 
  }
  &:nth-of-type(3) {
    padding-left: 5px; 
    /* @media (min-width: 1200px) {
      position: absolute; 
      bottom: 70px;
    } */
  } 
    &:nth-of-type(4) {
    /* @media (min-width: 1200px) {
      position: absolute; 
      bottom: 20px;
    } */
  }
  li {
    a {
      font-size: ${(props) => props.theme.fonts.smallSize}; 
      color: ${(props) => props.theme.colors.white};
      font-weight: 500; 
      cursor: pointer;
    }
  }
}

@media (min-width: 568px) {
  padding: 0 50px; 
  padding-bottom: 5px; 
}
@media (min-width: 768px) {
  padding: 0 100px; 
  padding-bottom: 5px; 
  ul {
  &:nth-of-type(2) {
    padding-right: 0; 
  }
  &:nth-of-type(3) {
    padding-left: 0; 
  } 
}
}
@media (min-width: 1200px) {
  padding: 0; 
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: unset; 
  ul {
    width: 100%; 
    position: relative; 
    /* &:first-of-type {
    &:hover {
    &::after {
      content: '';
      position: absolute;
      height: 32px; 
      width: 1px; 
      background-color: rgba(255, 255, 255, .2);
      right: 45px;
      top: calc(50% - 16px);
      pointer-events: none;
    }
  }
} */
    li {
      width: 100%; 
      a {
        width: 100%; 
        height: 50px; 
        align-items: center;
        display: flex; 
        font-size: 14px; 
        font-weight: 400;
      }
    }
  }
}
`

export const PlusButton = styled.div`
height: 36px; 
width: 36px; 
background-color: ${props => props.theme.colors.red}; 
position: absolute; 
bottom: 30px; 
left: calc(50% - 18px); 
z-index: 999999; 
border-radius: 27px; 
box-shadow: 0 0 10px rgba(24, 31, 56, 0.3); 
display: flex;
align-items: center;
justify-content: center;
@media (min-width: 768px) {
  height: 50px; 
  width: 50px; 
  left: calc(50% - 25px); 
  bottom: 25px; 
}
@media (min-width: 1200px) {
  display: none;
}
`

export const AdminList = styled.div`
position: absolute;
bottom: 20px;
width: 100%;
`
