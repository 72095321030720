import styled from "styled-components/macro";

export const StyledPickerRow = styled.div`
  &:nth-child(odd) {
    background: ${(props) => props.theme.colors.primary};
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;

`;

export const PickerRadioFieldContainer = styled.div`
    margin-right: 15px;
    width: 15px; height: 15px;
    position: relative;
    //dash
    &:after {
      content :"";
      position:absolute;
      right: -10px; top:50%; transform: translateY(-50%);
      width: 6px; height: 1px;
      background :${props => props.theme.colors.mgrey};
    }
    &:last-child {
      margin-right: 0;
      &:after {
        display: none;
      }
  }
`;

export const PickerRadioField = styled.input`
  /* width: 15px;
  height: 15px; */
  position: absolute;
  left: -9999px;

  & + label {
    position:relative;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 15px;
      height: 15px;
      border: 1px solid ${props => props.theme.colors.mgrey};
      border-radius: 100%;
      background: #fff;
    }
    &:after {
      content: '';
      width: 9px;
      height: 9px;
      background: ${props => props.theme.colors.red};
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
  }
    &:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
    &:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }
  
`;

export const StyledPickerLabels = styled.div`
  position: absolute;
  z-index: 0;
  display: flex;
  width: 100%;
  > div { font-size: 9px; }
  justify-content: space-between;
  top: -35px;
`;


export const PickerButtonRow = styled.div`
    position: relative;
    display:flex; align-items:center;
`;

export const StyledPickerContainer = styled.div`
  padding-top: 25px;
  @media (max-width: 991px) {
    ${(props) => (props.mt !== null ? `margin-top: ${props.mt}px;` : ``)}
  }
`;