import styled from "styled-components/macro";
import backgroundImage from './SharedShortlistTopBarBackground.png';

export const StyledSharedShortlistTopBar = styled.nav`
  position: relative;
  min-height: 270px;
  padding: 20px 0;
  z-index: 1;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
  margin-bottom: 48px;
  width: 100%;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-image: url(${backgroundImage});
    background-size: 50%;
    background-position: -10% 40%;
  }
`;
