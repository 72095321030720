import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { deleteCandidateApplication } from '../../../../api/endpoints/candidate';
import { ResetButton } from '../../../../components/Button';
import { DispatchMessageContext } from '../../../../context/MessageContext';
import { getTitleAttribute } from '../../../../utils/status';
import { capitalize, formatDate } from '../../../../utils/various';

const ApplicationCard = ({ application, setApplications }) => {
  const { t } = useTranslation();
  const history = useHistory();
  //Error dispatch
  const messageDispatch = useContext(DispatchMessageContext);

  const handleDelete = async (e) => {
    e.stopPropagation();

    const confirm = window.confirm(
      t(
        'component.card.application.confirm_delete_popup',
        'Delete this application?'
      )
    );

    if (confirm) {
      const res = await deleteCandidateApplication({
        selector: application.id,
      });
      const { success } = res;
      if (success) {
        //update list
        setApplications((prevApplications) => {
          return prevApplications.filter(
            (app) => app.id !== application.id
          );
        });

        messageDispatch({
          type: 'set_message',
          payload: 'Application deleted',
        });
      }
    }
  };

  if (!application) return;

  return (
    <StyledApplicationCard
      clickAble={
        (application.status === 'invited' ||
          application.status === 'draft') &&
        application.position.status === 'active'
      }
      onClick={() => {
        if (
          (application.status === 'invited' ||
            application.status === 'draft') &&
          application.position.status === 'active'
        ) {
          history.push(`/position/${application.position.uuid}`);
        }
      }}
    >
      <div>
        {application.position.organization.name}
        <StyledDeleteButton onClick={handleDelete} ></StyledDeleteButton>
      </div>
      <div>{application.position.title}</div>
      <div>
        {application.position.status === 'inactive' && application.status === 'draft'
          ? t('page.closed.header', 'Closed for applications')
          : capitalize(getTitleAttribute(application.status, t))}
        <div>{formatDate(application.createdAt)}</div>
      </div>
    </StyledApplicationCard>
  );
};

export default ApplicationCard;

const StyledDeleteButton = styled(ResetButton)`
  border: 2px solid ${(props) => props.theme.colors.dgrey};
  width: 20px;
  height: 20px;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  &:after,
  &:before {
    content: '';
    width: 8px;
    height: 2px;
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.dgrey};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:hover {
    border-color: ${(props) => props.theme.colors.red};
    &:after,
    &:before {
      background-color: ${(props) => props.theme.colors.red};
    }
  }
`;

const StyledApplicationCard = styled.div`
  border: 2px solid ${(props) => props.theme.colors.lgrey};
  background-color: ${(props) =>
    props.clickAble ? props.theme.colors.lgrey : 'transparent'};
  border-radius: 5px;
  padding: 16px;
  color: #7e7e7e;
  font-size: 14px;
  &:hover {
    border-color: #464646;
  }

  cursor: ${(props) => (props.clickAble ? 'pointer' : 'auto')};

  /* margin-top: 30px; */
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: inherit;

  > div {
    /* flex: 1; */
    /* border-bottom: 1px solid red; */
    display: flex;
    justify-content: space-between;
    &:nth-child(2) {
      margin-top: 12px;
      font-size: 18px;
      color: #2b2c2a;
      font-weight: bold;
    }
    &:nth-child(3) {
      flex: 1;
      display: flex;
      align-items: flex-end;
    }
  }
`;
