
import styled from "styled-components/macro";

/* ${props => props.active ? ' font-weight: 900;' : ''}; */
export const OrganizationWrapper = styled.div`
transition: all 0.2s ease-in-out;
background-color: #ffffff;

${props => props.active ? `
background-color: #f9f9f9;
h1, h2, p{
  font-weight: 900 !important;
}
` : null
  }

  &:hover {
    background-color: #f9f9f9;
  }

  &:last-of-type {
   > div > div{
      border-bottom: none;
    }
  }
`

export const TableContent = styled.div`
  padding: 24px 12px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgb(240, 240, 243);

  h1 {
    font-size: 18px;
  }

  h2 {
    font-weight: 500;

    &:last-of-type {
      font-weight: 400; 
    }
  }


  h3 {
    font-size: 12px;
    color: ${props => props.theme.colors.faceliftBlueLight}; 
    text-transform: uppercase;
    margin-right: 25px; 
    font-weight: 400; 
    @media (max-width: 1199px) {
        display: none;
    }
  }
  @media (min-width: 1200px) {
    padding: 15px 20px; 
  }
`

export const LargeOrganizationCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9F9F9;
  padding: 20px;
  width: 100%;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`
