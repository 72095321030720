import styled from "styled-components/macro";

export const SupportAccessWrapper = styled.div`
background-color: #f3f3f5;
padding: 25px; 
height: auto; 
min-height: 100%;
animation: fadeIn .5s ease forwards; 
@keyframes fadeIn {
    0% {
        opacity: 0; 
    }
    100% {
        opacity: 1; 
    }
}
`

export const Scrollable = styled.div`
  height: calc(100% - 70px);
  overflow: scroll; 
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important
  }
`

export const ManageOrganizations = styled.div`
  display: flex; 
  flex-wrap: wrap; 
  justify-content: space-between;
`

export const StyledTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.colors.faceliftBlueLighter}; 
  border-radius: 5px; 
  background-color: ${props => props.theme.colors.white};
  @media (max-width: 1200px) {
    margin-bottom: 25px;
  }
`;

export const TableHeader = styled.div`
padding: 12px 12px; 
box-shadow: 0 3px 6px ${props => props.theme.colors.faceliftBlueLighter};
border-bottom: 2px solid ${props => props.theme.colors.faceliftBlueLighter};
@media (min-width: 1200px) {
    padding: 12px 20px; 
}
`

export const TableSearch = styled.div`
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  width: 100%;
  margin-bottom: 1rem;
`

export const TableHeaderElements = styled.div`
  display: flex; 
  width: 100%;

  h2 {
    font-weight: 500;
  }

  p {
    font-size: 12px;

    @media (max-width: 768px) {
      display: none; 
    } 
  }
`

export const CurrentSuperOrganization = styled.div`
  display: flex; 
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px 20px; 
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
`

export const NoOrganizationCard = styled.div`
  display: flex; 
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px 20px; 
  background-color: #ffffff;
  border-bottom: 1px solid #e8e8e8;
`