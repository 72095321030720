import React, { useState, useEffect } from 'react';
import { StyledTopBar } from './style';
import { Grid, Row, Col } from '../Grid';
import { Icon, LoginButton } from '../index';
import { Link, useLocation } from 'react-router-dom';

import LanguageSelect from '../LanguageSelect/LanguageSelect';
import { useTranslation } from 'react-i18next';
import CompanyLogo from '../../candidate/components/CompanyLogo';
import { selectPageWithHeader } from '../../candidate/views/Application/utils';

/**
 * This is a top bar used in application forms, candidate account page, and shortlist share page
 */
const GenericTopBar = ({
  orgPage,
  orgUrl,
  orgName,
  orgLogo,
  includeAccount,
  isAuthenticated,
  manageShareholders,
  position,
  loadedPosition,
  onLanguageChange,
  user,
}) => {
  const { t, i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(user ? user.language : i18n.language);

  //change language to position language
  useEffect(() => {
    if (loadedPosition && position && position.positionLanguage) {
      const lang = position.positionLanguage;
      i18n.changeLanguage(lang);
      setActiveLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  //If we have a career page header do not use generic top bar
  const query = new URLSearchParams(useLocation().search);
  const cpid = query.get('cpid');
  const { header } = selectPageWithHeader(position?.pages, cpid);
  if (header) return <></>;

  return (
    <StyledTopBar manageShareholders={manageShareholders}>
      <Grid expand>
        <Row>
          <Col size={12} vCenter break="sm">
            {/* hitalento logo or organization name */}
            {orgName ? (
              <CompanyLogo
                path={orgUrl}
                name={orgName}
                logoPath={
                  position?.branding?.files[0]?.signedRequest ||
                  orgLogo
                }
              />
            ) : (
              <Link to="/">
                <Icon icon="logo_with_name" color="#fff" />
              </Link>
            )}
          </Col>
          <Col size={12} right vCenter break="sm">
            {/* account or login button */}
            {!orgPage && (
              <LanguageSelect
                noBorders
                inverted
                name="language"
                onChange={(e) => {
                  i18n.changeLanguage(e);
                  setActiveLanguage(e);
                  if (onLanguageChange) {
                    onLanguageChange(e);
                  }
                }}
                value={activeLanguage}
                alignRight
              />
            )}
            {includeAccount && (
              <>
                {isAuthenticated ? (
                  <Link to="/account" style={{ color: '#fff', marginLeft: '10px' }}>
                    {t('common.account')}
                  </Link>
                ) : (
                  <LoginButton
                    tiny
                    transparent
                    style={{ color: '#fff' }}
                  >
                    {t('common.login')}
                  </LoginButton>
                )}
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </StyledTopBar>
  );
};

export default GenericTopBar;
