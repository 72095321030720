import React, { useRef, useEffect } from "react";
import styled from "styled-components/macro";
import { Typography } from "../Typography";
import Icon from "../Icon";
import CountryCode from "./CountryCode";
import { useTranslation } from "react-i18next";

export const inputPadding = (props) => {
  if (props.tiny) return "10px 15px";
  if (props.micro) return "5px 10px";
  return "20px";
};

export const CTextField = styled.div`
  font-size: ${(props) =>
    props.micro ? props.theme.fonts.smallSize : props.theme.fonts.defaultSize};
  font-weight: 400;
  color: ${(props) => props.theme.colors.dgrey};
  padding: ${(props) => inputPadding(props)};
  ${(props) => (props.padding ? `padding: ${props.padding};` : null)};
  ${(props) => (props.height ? `height: ${props.height};` : null)};
  width: 100%;
  outline: none;
  resize: none;
  height: 100%;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.lgrey};
  ${(props) => (props.noBorder ? "border: none" : null)};
  &::placeholder {
    color: ${(props) => props.theme.colors.faceliftBlue};
    opacity: 0.6;
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.dgrey};
  }
  &:disabled {
    background-color: #f3f3f5;
    color: ${(props) => props.theme.colors.faceliftBlueLight};
  }
  &[contentEditable="true"]:empty:before {
    content: attr(data-text);
  }
`;

export const TextField = styled.textarea`
  font-size: ${(props) =>
    props.micro ? props.theme.fonts.smallSize : props.theme.fonts.defaultSize};
  font-weight: 400;
  color: ${(props) => props.theme.colors.faceliftBlueLight};
  padding: ${(props) => inputPadding(props)};
  width: 100%;
  outline: none;
  ${(props) => (props.noResize ? `resize: none;` : `resize: vertical;`)}
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.faceliftBlueLighter};
  ${(props) => (props.noBorder ? "border: none" : null)};
  height: 80px;
  &::placeholder {
    color: ${(props) => props.theme.colors.faceliftBlueLight};
    opacity: 1;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: #f3f3f5;
    color: ${(props) => props.theme.colors.faceliftBlueLight};
  }
  ${(props) =>
    props.applicant &&
    `
  border: 1px solid rgba(24, 31, 56, 0.15);
  color: rgba(0, 0, 0, 0.5);
  line-height: 20px;
  `}
`;

export const TextFieldContainer = styled.div`
  height: 100%;
  & > .feedback {
    text-transform: uppercase;
    color: #444a5e;
    opacity: ${(props) => (props.feedbackOn ? 1 : 0)};
    transition: all 0.5s ease-in-out;
    align-items: center;
    justify-content: center;
    display: flex;
    position: absolute;
    text-transform: capitalize;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    display: flex;
    border-radius: 5px;
    top: -30px;
    left: 16px;
    font-size: 14px;
    font-weight: 500;
    @media (min-width: 992px) {
      display: none;
    }
  }
`;

export const InputField = styled.input`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.faceliftBlue};
  padding: 12px 20px;
  ${(props) =>
    props.countryCode ? `text-indent:50px; position: relative;` : ``}
  ${(props) => (props.countryCode ? `position: relative;` : ``)}
  ${(props) =>
    props.inputWidth ? `width: ${props.inputWidth}` : `width: 100%`};
  min-height: 40px;
  outline: none;
  background-color: white;
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid rgba(24, 31, 56, 0.15);
  ${(props) => props.noBorder && `border: none`};
  ${(props) => (props.mt ? `margin-top: ${props.mt}px` : null)};
  ${(props) =>
    props.mw &&
    `
    max-width: 300px; 
    display: flex;
    align-items: center; 
    margin: 0 auto;
    margin-top: 24px; 
    `}
  &::placeholder {
    color: ${(props) => props.theme.colors.faceliftBlue};
    opacity: 0.6;
    font-size: 14px;
  }
  &:focus {
    outline: none;
  }
  /* Change the white to any color */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    border: 1px solid #dbdce1;
  }

  &:disabled {
    background-color: #f3f3f5;
    color: ${(props) => props.theme.colors.faceliftBlueLight};
  }

  ${(props) =>
    props.notEditable &&
    `
    background-color: #f3f3f5;
    color:${props.theme.colors.faceliftBlueLight};
  `}

  a {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const SelectField = styled.select`
  display: block;

  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.353' height='6.383' viewBox='0 0 11.353 6.383'%3E%3Cpath id='Path_158' data-name='Path 158' d='M875.123,2837.5l4.969,4.969,4.969-4.969' transform='translate(-874.416 -2836.793)' fill='none' stroke='%23555' stroke-linecap='round' stroke-width='1'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  background-size: 0.65em auto, 100%;

  font-size: ${(props) =>
    props.micro ? props.theme.fonts.smallSize : props.theme.fonts.defaultSize};
  font-weight: 400;
  color: ${(props) => props.theme.colors.dgrey};
  padding: ${(props) => inputPadding(props)};
  width: 100%;
  outline: none;
  margin: 0;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.lgrey};
  &::placeholder {
    color: ${(props) => props.theme.colors.mgrey};
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.dgrey};
  }

  &:disabled {
    background-color: #f3f3f5;
    color: ${(props) => props.theme.colors.faceliftBlueLight};
  }
  ${(props) => props.mt && `margin-top: ${props.mt}px !important;`}
`;

export const Checkbox = styled.input`
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.mgrey};
  width: 15px;
  min-width: 15px;
  height: 15px;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
`;

export const CheckBoxLabel = styled.label`
  cursor: pointer;
  margin: 10px 15px 10px 0px;
  ${(props) => (props.mt !== null ? `margin-top: ${props.mt}px;` : ``)}
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}px;` : ``)}
  display: flex;
  align-items: ${(props) => (props.alignToTop ? "flex-start" : "center")};
  position: relative;
  > div {
    //the checkmark
    position: absolute;
    top: ${(props) => (props.alignToTop ? "0px" : "50%")};
    left: 3px;
    transform: translate(0, ${(props) => (props.alignToTop ? "5px" : "-50%")});
  }
`;

export const InputLabel = styled.label`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${(props) =>
    props.boldLabel ? "800 !important" : "400 !important"};
  text-align: left;
  margin-bottom: 20px;
  color: #181f38 !important;
  white-space: normal;
  margin-top: 24px;
  margin-bottom: 10px;
  ${(props) =>
    props.bold &&
    `
    font-weight: 500 !important;
    `}
  display: inline-block;
  &:before {
    content: "* ";
    display: ${(props) => (props.required ? "inline" : "none")};
    color: ${(props) => props.theme.colors.highlight};
  }
  &:after {
    content: "s";
    opacity: 0;
    display: ${(props) => (props.byline ? "none" : "inline")};
  }
  & + * {
    margin-top: 0px;
  }
  ${(props) =>
    props.settings &&
    `
  font-size: 14px; 
  font-weight: 500; 
  color: #181F38;
  margin: 0; 
  min-width: 100px;
  @media (min-width: 1200px) {
    min-width: unset; 
  } 
  `}
  ${(props) =>
    props.block &&
    `
  margin-bottom: 12px;
  font-weight: 500 !important;
  `}
  &:first-of-type {
    margin-top: 0;
  }
`;

export const LabelByline = styled.div`
  /* font-size: 9px; */
  /* font-size: ${(props) => (props.fontSize ? props.fontSize : "9px")}; */
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 4px;
  font-size: 14px;
  ${(props) => props.pt && `padding-top: ${props.pt}px`};
  color: #747988;
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}px` : null)};
  @media (min-width: 1200px) {
    margin-top: 10px;
    margin-bottom: -5px;
    ${(props) => (props.mbLG ? `margin-bottom: ${props.mbLG}px` : null)};
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}px;` : null)}
  ${(props) => props.pb && `padding-bottom: ${props.pb}px`}
  ${(props) => (props.width ? `width: ${props.width};` : null)};
  ${(props) => props.countryCode && `position: relative;`}
  ${(props) =>
    props.inButton &&
    `
    background: ${
      props.checked ? props.theme.colors.green : props.theme.colors.lgrey
    };
    border-radius: 35px;
    padding : 3px 6px 3px 16px;
    display: inline-block;
    * { stroke: ${
      props.checked ? "#fff" : props.theme.colors.dgrey
    }; border-color: ${props.checked ? "#fff" : props.theme.colors.dgrey}; ${
      props.checked && "color:#fff;"
    } }
  `}
  ${(props) => (props.mb ? `margin-bottom: ${props.mb}px;` : null)};
  ${(props) => (props.height ? `height: ${props.height};` : null)};
  ${(props) => (props.width ? `width: ${props.width};` : null)};
  ${(props) =>
    props.selectField &&
    `
    select {
      font-size: 10px; 
      margin: 0;
      padding: 10px 20px; 
      font-size: 14px; 
      border: 1px solid rgba(24, 31, 56, 0.15); 
      opacity: 0.6; 
      min-width: 150px; 
      color: ${(props) => props.theme.colors.faceliftBlue};
    }
  `}
  ${(props) =>
    props.editPositionTitle &&
    `
  width: 100%; 
    input {
      border: 0; 
      padding: 0; 
      font-size: 18px; 
      color: rgba(24, 31, 56, 0.6); 
      margin: 0;
      background-color: white !important;
    }

  `}
  ${(props) =>
    props.block &&
    `
  padding: 26px 0; 
  border-bottom: 1px solid #F0F0F3; 
  `}
  ${(props) =>
    props.applicationSelect &&
    `
        @media (min-width: 992px) {
        margin-top: 54px; 
      }
  `}
  ${(props) =>
    props.hideLabel &&
    `
  label {
    display: none;
  }
  `}
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.hideOnMobile &&
    `
 @media (max-width: 991px) {
  display: none;
 }
 `}
  @media (min-width: 992px) {
    ${(props) => (props.pbMD ? `padding-bottom: ${props.pbMD}px;` : null)};
  }
  ${(props) => (props.pt ? `padding-top: ${props.pt}px;` : null)};

  ${(props) => (props.noBorderBottom ? `border-bottom: none` : null)};
  ${(props) =>
    props.notes &&
    `
  padding-bottom: 10px !important;
  max-height: 150px;
  overflow-y: auto; 
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  `}
  @media (min-width: 1600px) {
    ${(props) => props.notes && `padding-bottom: 20px !important;`}
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  border-radius: 5px;

  a {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    width: 50px;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: lightgrey;
    background-color: #f1f1f1;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #dedede;
    }
  }
`;

export const CheckboxWidthLabel = ({
  id,
  children,
  label,
  required,
  checked,
  alignToTop,
  mt,
  mb,
  inButton,
  ...rest
}) => {
  return (
    <InputGroup inButton={inButton} checked={checked}>
      <CheckBoxLabel
        alignToTop={alignToTop}
        htmlFor={id}
        className={checked ? "checked-label" : ""}
        mt={mt}
        mb={mb}
      >
        <Checkbox id={id} {...rest} />
        {checked && <Icon icon="checkmark_small" />}
        <span>{label}</span>
        {children}
      </CheckBoxLabel>
    </InputGroup>
  );
};

export const CheckboxWidthLabelNoHtmlFor = ({
  id,
  children,
  label,
  required,
  checked,
  alignToTop,
  mt,
  mb,
  inButton,
  ...rest
}) => {
  return (
    <InputGroup inButton={inButton} checked={checked}>
      <CheckBoxLabel
        alignToTop={alignToTop}
        className={checked ? "checked-label" : ""}
        mt={mt}
        mb={mb}
      >
        <Checkbox {...rest} checked={checked} />
        {checked && <Icon icon="checkmark_small" />}
        <span>{label}</span>
        {children}
      </CheckBoxLabel>
    </InputGroup>
  );
};

export const InputLabelWithByline = ({
  required,
  htmlFor,
  label,
  boldLabel,
  byline,
  inverted,
  settings,
  bold,
}) => {
  return (
    <InputLabel
      settings={settings}
      required={required}
      htmlFor={htmlFor}
      byline={byline}
      inverted={inverted}
      bold={bold}
      boldLabel={boldLabel}
    >
      {label}
      {byline && <LabelByline>{byline}</LabelByline>}
    </InputLabel>
  );
};

export const InputFieldWithLabel = ({
  id,
  label,
  boldLabel,
  required,
  byline,
  inverted,
  countryCode,
  countryCodeChange,
  editPositionTitle,
  mb,
  pt,
  positionTitle,
  pb,
  borderBottom,
  mbLG,
  block,
  mw,
  searchIcon,
  noBorder,
  mt,
  autoFocus,
  ...rest
}) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <InputGroup
      editPositionTitle={editPositionTitle}
      block={block}
      pb={pb}
      positionTitle={positionTitle}
      pt={pt}
      countryCode={countryCode}
      mt={mt}
      mb={mb}
    >
      {label !== false ? (
        <InputLabel
          required={required}
          boldLabel={boldLabel}
          htmlFor={id}
          byline={byline}
          inverted={inverted}
          block={block}
        >
          {label}
          {byline && (
            <LabelByline mb={mb} mbLG={mbLG}>
              {byline}
            </LabelByline>
          )}
        </InputLabel>
      ) : (
        ""
      )}
      <InputField
        id={id}
        ref={inputRef}
        {...rest}
        inverted={inverted}
        countryCode={countryCode}
        mw={mw}
        searchIcon={searchIcon}
        mt={mt}
        noBorder={noBorder}
      />
      {countryCode && (
        <CountryCode selectedValue={countryCode} onChange={countryCodeChange} />
      )}
    </InputGroup>
  );
};

export const InputFieldWithLink = ({
  id,
  label,
  boldLabel,
  required,
  byline,
  inverted,
  countryCode,
  countryCodeChange,
  editPositionTitle,
  mb,
  pt,
  positionTitle,
  pb,
  borderBottom,
  mbLG,
  block,
  mw,
  searchIcon,
  noBorder,
  mt,
  href,
  ...rest
}) => {
  return (
    <InputGroup
      editPositionTitle={editPositionTitle}
      block={block}
      pb={pb}
      positionTitle={positionTitle}
      pt={pt}
      countryCode={countryCode}
      mt={mt}
      mb={mb}
    >
      {label !== false ? (
        <InputLabel
          required={required}
          boldLabel={boldLabel}
          htmlFor={id}
          byline={byline}
          inverted={inverted}
          block={block}
        >
          {label}
          {byline && (
            <LabelByline mb={mb} mbLG={mbLG}>
              {byline}
            </LabelByline>
          )}
        </InputLabel>
      ) : (
        ""
      )}
      <InputWrapper>
        <InputField
          id={id}
          {...rest}
          inverted={inverted}
          countryCode={countryCode}
          mw={mw}
          searchIcon={searchIcon}
          mt={mt}
          noBorder={noBorder}
          href={href}
        />
        {href && (
          <a href={href} target="_blank">
            <Icon ml={6} pointer icon="preview" />
          </a>
        )}
      </InputWrapper>
      {countryCode && (
        <CountryCode selectedValue={countryCode} onChange={countryCodeChange} />
      )}
    </InputGroup>
  );
};

export const SelectFieldWithLabel = ({
  id,
  label,
  required,
  byline,
  mt,
  hideLabel,
  width,
  hideOnMobile,
  ...rest
}) => {
  return (
    <InputGroup
      applicationSelect
      selectField
      hideLabel={hideLabel}
      hideOnMobile={hideOnMobile}
      width={width}
    >
      {label !== false ? (
        <InputLabel required={required} htmlFor={id} byline={byline}>
          {label}
          {byline && <LabelByline>{byline}</LabelByline>}
        </InputLabel>
      ) : (
        ""
      )}
      <SelectField id={id} {...rest} mt={mt} />
    </InputGroup>
  );
};

// Text field acting as a div to expand as you type using contentEditable.
// For any regular text fields use the Textfield component instead.

export const CETextField = ({
  id,
  label,
  byline,
  required,
  trackValue,
  feedbackOn,
  maxLength,
  notes,
  bigTracker,
  placeholder,
  padding,
  height,
  pbMD,
  text,
  contentEditable,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <InputGroup height={height} pbMD={pbMD} notes>
      {label ? (
        <InputLabel required={required} htmlFor={id} byline={byline}>
          {label}
          {byline && <LabelByline>{byline}</LabelByline>}
        </InputLabel>
      ) : null}
      <TextFieldContainer feedbackOn={feedbackOn}>
        <span className="feedback">
          <Icon mr="4" icon="save_plus" />
          <span>{t("common.saved")}</span>
        </span>

        <CTextField
          data-text={placeholder}
          suppressContentEditableWarning={true}
          padding={padding}
          contentEditable={contentEditable}
          id={id}
          {...rest}
          placeholder={placeholder}
        >
          {text}
        </CTextField>
      </TextFieldContainer>
    </InputGroup>
  );
};

export const TextFieldWithLabel = ({
  id,
  label,
  boldLabel,
  byline,
  required,
  trackValue,
  applicant,
  feedbackOn,
  maxLength,
  bigTracker,
  placeholder,
  nomargin,
  block,
  pt,
  mb,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <InputGroup block={block} pt={pt} mb={mb}>
      <InputLabel
        required={required}
        boldLabel={boldLabel}
        htmlFor={id}
        byline={byline}
      >
        {label}
        {byline && <LabelByline>{byline}</LabelByline>}
      </InputLabel>
      <TextFieldContainer feedbackOn={feedbackOn}>
        <span className="feedback">
          <span>{t("common.saved")}</span>
          <Icon ml={6} icon="checkmark_small" />
        </span>

        <TextField
          applicant={applicant}
          maxLength={maxLength || "500"}
          placeholder={placeholder}
          style={{ minHeight: "120px", padding: 12 }}
          id={id}
          {...rest}
        />
      </TextFieldContainer>
      {bigTracker ? (
        <Typography tag="p" larger right mt={12} colorMute nomargin={nomargin}>
          {trackValue.length} / {maxLength || "500"}
        </Typography>
      ) : (
        <Typography tag="p" defaultSize mt="0" lowOpacity nomargin={nomargin}>
          {trackValue.length} / {maxLength || "500"}
        </Typography>
      )}
    </InputGroup>
  );
};

export const TextFieldNoLabel = ({
  id,
  trackValue,
  applicant,
  feedbackOn,
  maxLength,
  bigTracker,
  placeholder,
  nomargin,
  block,
  pt,
  mb,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <InputGroup block={block} pt={pt} mb={mb}>
      <TextFieldContainer feedbackOn={feedbackOn}>
        {feedbackOn && (
          <span className="feedback">
            <span>{t("common.saved")}</span>
            <Icon ml={6} icon="checkmark_small" />
          </span>
        )}
        <TextField
          applicant={applicant}
          maxLength={maxLength || "500"}
          placeholder={placeholder}
          style={{ minHeight: "120px", padding: 12 }}
          id={id}
          {...rest}
        />
      </TextFieldContainer>
      {trackValue &&
        (bigTracker ? (
          <Typography
            tag="p"
            larger
            right
            mt={12}
            colorMute
            nomargin={nomargin}
          >
            {trackValue.length} / {maxLength || "500"}
          </Typography>
        ) : (
          <Typography tag="p" defaultSize mt="0" lowOpacity nomargin={nomargin}>
            {trackValue.length} / {maxLength || "500"}
          </Typography>
        ))}
    </InputGroup>
  );
};
