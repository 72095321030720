import * as Yup from "yup";
import { testUrl } from "../../../utils/validation";
import { convertPeriod } from "./components/util";

export const phoneRegExp =
  /^\+?((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/;

export const validEmail = (email) => {
  const schema = Yup.object().shape({
    email: Yup.string().required().email(),
  });
  return schema.isValid({ email: email });
};

const fileSizeCheck = (value) => {
  let valid = true;
  if (value) {
    if (Array.isArray(value)) {
      value.forEach((file) => {
        if (file) {
          const size = file.size / 1024 / 1024;
          if (size > 5) {
            // 5MB
            valid = false;
          }
        }
      });
    } else {
      const size = value.size / 1024 / 1024;
      if (size > 5) {
        // 5MB
        valid = false;
      }
    }
  }
  return valid;
};

const fileTypeCheck = (value) => {
  let valid = true;
  if (value) {
    if (Array.isArray(value)) {
      value.forEach((file) => {
        if (file) {
          if (!["image/jpeg", "image/png"].includes(file.type)) {
            valid = false;
          }
        }
      });
    } else {
      if (!["image/jpeg", "image/png"].includes(value.type)) {
        valid = false;
      }
    }
  }
  return valid;
};

const checkCompetencyPicker = (positionObject, competencies) => {
  let valid = true;
  if (positionObject) {
    if (positionObject.competencies) {
      positionObject.competencies.forEach((comp) => {
        if (!competencies[convertPeriod(comp, true)]) {
          valid = false;
        }
      });
    }
  }
  return valid;
};

const checkLanguagePicker = (positionObject, competencies) => {
  let valid = true;
  if (positionObject) {
    if (positionObject.languages) {
      positionObject.languages.forEach((comp) => {
        if (!competencies[convertPeriod(comp, true)]) {
          valid = false;
        }
      });
    }
  }
  return valid;
};

const checkExperience = (field, value) => {
  let valid = true;
  // value.forEach((experience) => {
  //   if (experience[field] === "" || experience[field] === undefined) {
  //     valid = false;
  //   }
  // });
  return valid;
};

const checkLinks = (value) => {
  let valid = true;
  if (value["linkedin"] && value["linkedin"].length > 100) valid = false;
  if (value["other"] && value["other"].length > 100) valid = false;
  if (value["linkedin"]) valid = testUrl(value["linkedin"]);
  if (value["other"]) valid = testUrl(value["other"]);

  return valid;
};

const validationSchema = (positionObject) => {
  const { light, mandatoryFields } = positionObject;

  //build validation schema dynamically from position object!
  return Yup.object().shape({
    education: Yup.array().test(
      "Test education",
      "Please provide an institution name",
      (value) => {
        return checkExperience("institution", value);
      }
    ),
    experience: Yup.array().test(
      "Test experience",
      "Please provide a experience workplace name",
      (value) => {
        return checkExperience("workplace", value);
      }
    ),
    languages: Yup.object()
      .required("Required")
      .test(
        "Test language",
        "Rate yourself on the required skill",
        (value) => {
          return checkLanguagePicker(positionObject, value);
        }
      ),
    competencies: Yup.object()
      .required("Required")
      .test(
        "Test competency",
        "Rate yourself on the required skill",
        (value) => {
          return checkCompetencyPicker(positionObject, value);
        }
      ),

    // NOT IN SHORT
    links: Yup.object().test(
      "Test links",
      "The links are either not valid or too long",
      (value) => {
        return checkLinks(value);
      }
    ),

    // SHORT ABOUT / COVER LETTER CAN BE OPTIONAL
    about:
      light && mandatoryFields.indexOf("about") === -1
        ? Yup.string().max(500, "About/Cover letter text is too long!")
        : Yup.string()
          .required("About/Cover letter text is required")
          .max(500, "About/Cover letter text is too long!"),

    // NOT IN SHORT
    // mainTitle: light ? Yup.string().max(100, "The headline is too long!") : Yup.string().required(" A headine is required").max(100, "The headline is too long!"),
    mainTitle: Yup.string().max(100, "The headline is too long!"),
    yearsExperience: light
      ? Yup.number()
        .min(0)
        .max(100)
        .required("Minimum 0 years of experience is required")
      : Yup.number()
        .required("Years of experience is required")
        .max(100)
        .min(0), //not required in light

    //NOT REQUIRED IN SHORT
    city: Yup.string().max(100, "City is too long!"),

    // country: light ? Yup.string().max(100, "Country is too long!") : Yup.string().required("Country is required").max(100, "Country is too long!"),
    country: Yup.string().max(100, "Country is too long!"),

    //FILES
    files: Yup.array().test(
      "Test competency",
      "Please upload a resumé",
      (filesArray) => {
        if (light && mandatoryFields.indexOf("upload_cv") > -1) {
          //must contain a file of type "resume"
          const file = filesArray.find((el) => el.docType === "resume");
          return file ? true : false;
        }
        return true;
        // return checkCompetencyPicker(positionObject, value);
      }
    ),

    //REQUIRED FOR SHORT / EXTENDED
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Invalid phone number"),
    email: Yup.string()
      .required("Email is required")
      .email("Must be a valid email address")
      .max(255, "Email is too long!"),
    lastName: Yup.string()
      .required("Last name is required")
      .min(2, "Last name is too short!")
      .max(50, "Last name is too looong!"),
    firstName: Yup.string()
      .required("First name is required")
      .min(2, "First name is too short!")
      .max(50, "First name is too looong!"),
  });
};

export default validationSchema;

export const validateApplication = async (
  objectToValidate,
  positionObject,
  t
) => {
  const shape = validationSchema(positionObject);
  return await shape.validate(objectToValidate);
};
