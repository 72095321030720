import moment from 'moment';
// import { isEmpty } from './cleanObject';
// import { isSubscriptionStatusOk } from './stripe_utils';

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDate = (utcString, includeTime, locale) => {
  moment.locale(locale || 'da');
  const formattedDate = moment(utcString);
  return formattedDate.format(
    `DD.MM.Y ${includeTime ? 'hh:mm' : ''}`
  );
};

export const formatSecondsToTime = (seconds) => {
  return moment.utc(seconds * 1000).format('m:ss');
};

export const getMonth = (nMonth, abbreviated, t) => {
  switch (parseInt(nMonth)) {
    case 1:
      return !abbreviated
        ? t('time.months.january')
        : t('time.months_abbreviated.january');
    case 2:
      return !abbreviated
        ? t('time.months.february')
        : t('time.months_abbreviated.february');
    case 3:
      return !abbreviated
        ? t('time.months.march')
        : t('time.months_abbreviated.march');
    case 4:
      return !abbreviated
        ? t('time.months.april')
        : t('time.months_abbreviated.april');
    case 5:
      return !abbreviated
        ? t('time.months.may')
        : t('time.months_abbreviated.may');
    case 6:
      return !abbreviated
        ? t('time.months.june')
        : t('time.months_abbreviated.june');
    case 7:
      return !abbreviated
        ? t('time.months.july')
        : t('time.months_abbreviated.july');
    case 8:
      return !abbreviated
        ? t('time.months.august')
        : t('time.months_abbreviated.august');
    case 9:
      return !abbreviated
        ? t('time.months.september')
        : t('time.months_abbreviated.september');
    case 10:
      return !abbreviated
        ? t('time.months.october')
        : t('time.months_abbreviated.october');
    case 11:
      return !abbreviated
        ? t('time.months.november')
        : t('time.months_abbreviated.november');
    case 12:
      return !abbreviated
        ? t('time.months.december')
        : t('time.months_abbreviated.december');
    default:
  }
};

export const limitWord = (string, limit) => {
  if (string.length > limit) {
    return string.substring(0, limit) + '...';
  } else {
    return string;
  }
};

export const fullName = (firstName, lastName) => {
  let fullName = firstName ? firstName : '';
  fullName += ' ';
  fullName += lastName ? lastName : '';
  if (fullName === ' ') fullName = 'N/A';
  return fullName;
};

export const formatAmount = (amount, currency) => {
  switch (currency) {
    case 'eur':
      return `€${amount / 100}`;
    default:
      return `${amount / 100} ${currency?.toUpperCase()}`;
  }
};

export const statusText = (status, t) => {
  switch (status) {
    case 'active':
      return t('common.active');
    case 'trialing':
      return t('component.subscription.status.trialing');
    case 'incomplete':
      return t('component.subscription.status.missing_payment');
    case 'past_due':
      return t('component.subscription.status.missing_payment');
    case 'incomplete_expired':
      return t('component.subscription.status.canceled');
    case 'unpaid':
      return t('component.subscription.status.canceled');
    case 'canceled':
      return t('component.subscription.status.canceled');
    default:
      return t('component.subscription.status.unknown');
  }
};

/**
 * Return true if user has privilege for an action ( activate position, invite recruiter ) returns false if not
 * @param {string} action the action required "activate_position" | "invite_recruiter"
 * @param {object} privileges { unsolicited: true, position: 1, recruiter: 1, career_page : 1}
 * @param {object} activeItems { unsolicited: true, position: 1, recruiter: 1, career_page : 1}
 * @returns {boolean}  true if has privileges for this action
 */
export const hasPrivilege = (action, privileges, activeItems) => {
  switch (action) {
    case 'activate_position': {
      return privileges.position > activeItems.position;
    }
    case 'invite_recruiter': {
      return privileges.recruiter > activeItems.recruiter;
    }
    case 'create_cp': {
      return privileges.career_page > activeItems.career_page;
    }
    default: {
      return false;
    }
  }
};

/* check or uncheck all items in a list
  input :
    -selected (state)
    -setSelected (setState)
    -all (original list)
  */
export const toggleCheckAll = async (selected, setSelected, all) => {
  //if none selected select all, if all selected deselect all
  if (selected.length > 0) {
    setSelected([]);
  } else {
    if (all.length > 0) {
      setSelected(all.map((app) => app.id));
    }
  }
};

/**
 * Get all applications with ids in selected
 * @param {array} selected array of application ids
 * @param {array} all array of applications
 * @returns {array} filtered list
 */
export const getSelectedApplications = (selected, all) => {
  return all.filter((app) => selected.indexOf(app.id) > -1);
};

/* select an item and add to state */
export const handleSelectApplications = (
  e,
  id,
  selected,
  setSelected
) => {
  if (e.target.checked) {
    //add to array
    setSelected([...selected, id]);
  } else {
    //remove from array
    const index = selected.indexOf(id);
    if (index > -1) {
      const splicedArray = [...selected];
      splicedArray.splice(index, 1);
      setSelected(splicedArray);
    }
  }
};

/**
 * Frontend search
 * Search positions or directories ( position title or directory name)
 * inputs
 * list (state)
 * setList (setState)
 * query (string)
 * clearList (setState) -- used to reload original list
 */
export const filterListByQuery = (
  list,
  setList,
  query,
  clearList
) => {
  if (query.length < 3 || !query) {
    clearList((prevState) => prevState + 1);
    return;
  }

  let modQuery = query.toLowerCase().trim();
  setList(
    list.filter(
      (item) =>
        (item.title && item.title.toLowerCase().includes(modQuery)) ||
        (item.name && item.name.toLowerCase().includes(modQuery))
    )
  );
};

/**
 * Calculate brightness value by RGB or HEX color.
 * @param color (String) The color value in RGB or HEX (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
export function brightnessByColor(color) {
  const colorString = String(color);
  const isHEX = colorString.indexOf('#') === 0;
  const isRGB = colorString.indexOf('rgb') === 0;
  var m, r, g, b;

  if (isHEX) {
    const hasFullSpec = colorString.length === 7;
    m = colorString.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
    if (m) {
      r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16);
      g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16);
      b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
    }
  }

  if (isRGB) {
    m = colorString.match(/(\d+){3}/g);
    if (m) {
      r = m[0];
      g = m[1];
      b = m[2];
    }
  }

  if (typeof r != 'undefined') {
    return (r * 299 + g * 587 + b * 114) / 1000;
  }
}

/**
 *
 * @param {string} color
 * @returns
 */
export function getInvertedColor(color) {
  if (brightnessByColor(color) < 125) {
    return '#fff';
  } else {
    return '#333';
  }
}

const NON_ANON_SUBSTATUS = ['hired', 'interview'];
/**
 * Determine whether application should be anonymized
 * if position.anon === true and shortlist === false
 * and
 * @param {object} application
 * @returns {boolean} true if should anonymized
 */
export const isAnonymized = (application) => {
  if (!application || !application?.position) return false;
  return (
    application.position.anon &&
    NON_ANON_SUBSTATUS.indexOf(application.substatus) === -1 &&
    !application.shortlist
  );
};
