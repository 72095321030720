import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { UserProvider } from "./context/UserContext";
import { createBrowserHistory } from "history";
import './i18n/i18n'; //translations
import { Suspense } from 'react/cjs/react.production.min';
import { Loader } from './components';
import 'font-awesome/css/font-awesome.min.css';

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const onRedirectCallback = () => {
  const history = createBrowserHistory();
  history.replace(window.location.pathname);
};

const domainName = window.location.hostname.replace(/^www\./, '');
if (domainName === 'recruiter.hitalento.com') {
  Sentry.init({
    dsn: "https://0295ac018fbf45118d19ab191d5c796d@o4504367273082880.ingest.sentry.io/4504367278260224",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Auth0Provider domain={domain} clientId={clientId} redirectUri={window.location.origin} audience={audience} onRedirectCallback={onRedirectCallback}>
      <UserProvider>
        <App />
      </UserProvider>
    </Auth0Provider>
  </Suspense>,
  document.getElementById("root")
);
