import React, { useContext } from "react";

import { ApplicationContext } from "../../../ApplicationContext";
import { StatePositionContext } from "../../../../../context/PositionContext";
import { useTranslation } from "react-i18next";

import {
  FileField,
  InputFieldWithLabel,
  TextFieldWithLabel,
} from "../../../../../../components";

import { Grid, Row, Col } from "../../../../../../components/Grid";

import { initialField } from "../../../../../../components/FileField/reducer";
import CompetenciesAndLanguages from "../CompetenciesAndLanguagesAccordion/CompetenciesAndLanguages";
import { parseResume } from "./parseResume";

const ShortForm = () => {
  //LANGUAGE
  const { t } = useTranslation();
  //POSITION STATE
  const { position } = useContext(StatePositionContext);

  //APPLICATION STATE
  const { state, dispatch } = useContext(ApplicationContext);

  // HANDLE BLUR
  const handleBlur = async (e) => {
    // silencio
  };

  // HANDLE STATE CHANGE
  const handleChange = (e) => {
    dispatch({
      type: "update_field",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  return (
    <>
      <Grid>
        <Row>
          <Col size={12} break="md">
            <InputFieldWithLabel
              name="firstName"
              // label={false}
              label={t("common.first_name")}
              boldLabel
              // placeholder={t("common.first_name")}
              required
              value={state.application.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
              mb={20}
            />
          </Col>
          <Col size={12} break="md">
            <InputFieldWithLabel
              name="lastName"
              // label={false}
              label={t("common.last_name")}
              boldLabel
              // placeholder={t("common.last_name")}
              required
              value={state.application.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              mb={20}
            />
          </Col>
        </Row>
        <Row>
          <Col size={12} break="md">
            <InputFieldWithLabel
              disabled={state.isLoggedIn}
              value={state.application.email}
              name="email"
              // label={false}
              label={t("common.email")}
              boldLabel
              // placeholder={t("common.email")}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              mb={20}
            />
          </Col>
          <Col size={12} break="md">
            <InputFieldWithLabel
              name="phoneNumber"
              // label={false}
              label={t("common.phone_number")}
              boldLabel
              // placeholder={t("common.phone_number")}
              value={state.application.phoneNumber}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              mb={20}
            />
          </Col>
        </Row>

        <Row>
          <Col size={12} break="md">
            <Row>
              <Col>
                <FileField
                  applicant
                  useStyleVars
                  required={
                    position?.mandatoryFields?.indexOf("upload_cv") > -1
                  }
                  type="document"
                  docType="resume"
                  label={t("application_form.upload_cv_label", "Upload CV")}
                  boldLabel
                  largeButton
                  mb={20}
                  onUploadStart={() => dispatch({ type: "upload_started" })}
                  onUploadEnd={() => dispatch({ type: "upload_ended" })}
                  initialFiles={
                    state.isLoggedIn &&
                    state.application.files?.filter(
                      (file) => file.docType !== "profileImage"
                    ).length > 0 &&
                    state.application?.files
                      .filter(
                        (file) =>
                          file.docType !== "profileImage" &&
                          file.docType === "resume"
                      )
                      .map((file, index) => {
                        return {
                          ...initialField,
                          id: index,
                          hasFile: true,
                          savedFile: file,
                        };
                      })
                  }
                  onUpload={async (file) => {
                    //set in addFiles
                    dispatch({ type: "add_files", payload: file });

                    //Update on server
                    if (state.isLoggedIn) {
                      handleBlur("files", {
                        files: [...state.application.files, file],
                        addFiles: [...state.application.addFiles, file.uuid],
                      });
                    }
                    //parse resume
                    const parsedResume = await parseResume(file.uuid, dispatch);
                  }}
                  onRemove={(file) => {
                    dispatch({ type: "remove_files", payload: file.uuid });

                    //Update on server
                    handleBlur("files", {
                      files: state.application.files.filter(
                        (rFile) => rFile.uuid !== file.uuid
                      ),
                      removeFiles: [
                        ...state.application.removeFiles,
                        file.uuid,
                      ],
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FileField
                  applicant
                  useStyleVars
                  dynamicAddRemove={true}
                  type="document"
                  docType="other"
                  label={t("application_form.documents_label")}
                  boldLabel
                  largeButton
                  mb={20}
                  onUploadStart={() => dispatch({ type: "upload_started" })}
                  onUploadEnd={() => dispatch({ type: "upload_ended" })}
                  initialFiles={
                    state.isLoggedIn &&
                    state.application.files?.filter(
                      (file) => file.docType !== "profileImage"
                    ).length > 0 &&
                    state.application?.files
                      .filter(
                        (file) =>
                          file.docType !== "profileImage" &&
                          file.docType !== "resume"
                      )
                      .map((file, index) => {
                        return {
                          ...initialField,
                          id: index,
                          hasFile: true,
                          savedFile: file,
                        };
                      })
                  }
                  onUpload={(file) => {
                    //set in addFiles
                    dispatch({ type: "add_files", payload: file });

                    //Update on server
                    if (state.isLoggedIn) {
                      handleBlur("files", {
                        files: [...state.application.files, file],
                        addFiles: [...state.application.addFiles, file.uuid],
                      });
                    }
                  }}
                  onRemove={(file) => {
                    dispatch({ type: "remove_files", payload: file.uuid });

                    //Update on server
                    handleBlur("files", {
                      files: state.application.files.filter(
                        (rFile) => rFile.uuid !== file.uuid
                      ),
                      removeFiles: [
                        ...state.application.removeFiles,
                        file.uuid,
                      ],
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col size={12} break="md">
            <TextFieldWithLabel
              applicant
              bigTracker
              mb={20}
              name="about"
              type="textarea"
              label={t("application_form.cover_letter_label", "Cover letter")}
              boldLabel
              placeholder={t(
                "application_form.cover_letter_placeholder",
                "Please describe your best qualifications and best milestones in your business career and private life."
              )}
              required={position?.mandatoryFields?.indexOf("about") > -1}
              value={state.application.about}
              trackValue={state.application.about}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>
      </Grid>
      {(position?.languages && position?.languages.length > 0) ||
      (position?.competencies && position?.competencies.length > 0) ? (
        <CompetenciesAndLanguages />
      ) : null}
    </>
  );
};

export default ShortForm;
