// React
import React, { useRef } from "react";

// Utility functions and hooks
import { useOffClick } from "../../recruiter/hooks/useOffClick";

// Icon
import Icon from "../Icon";

// Styled components
import {
  StyledModal,
  StyledModalContent,
  ModalHeader,
  CloseModal,
  ModalWidthContainer,
} from "./Modal.styled";

const Modal = ({
  tiny,
  blind,
  children,
  noBorder,
  onClickOutside,
  title,
  openAnimation,
  onClose,
  containWidth,
  noClose,
  contentPadding,
  icon,
}) => {
  const ref = useRef();

  useOffClick(ref, () => {
    if (onClickOutside) {
      onClickOutside();
    }
  });

  return (
    <StyledModal blind={blind}>
      <StyledModalContent tiny={tiny} noBorder={noBorder} ref={ref}>
        <ModalHeader>
          {icon && <Icon scale="1.2" icon={icon} />}
          <h2>{title}</h2>
        </ModalHeader>
        {!noClose && (
          <CloseModal>
            <Icon icon="cross" pointer onClick={onClose} />
          </CloseModal>
        )}
        <ModalWidthContainer
          containWidth={containWidth}
          contentPadding={contentPadding}
        >
          {children}
        </ModalWidthContainer>
      </StyledModalContent>
    </StyledModal>
  );
};

export default Modal;
