import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useReducer, useEffect } from "react";
import { getMe } from "../api/endpoints/me";
import axios from "axios";
import { useTranslation } from "react-i18next";

export const StateUserContext = createContext();
export const DispatchUserContext = createContext();

//Make a user state including state from api and auth0.
const userReducer = (state, action) => {
  switch (action.type) {
    case "auth0authentication":
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case "auth0error":
      return {
        ...state,
        error: action.payload,
      };
    case "auth0loading":
      return {
        ...state,
        auth0Loading: action.payload,
      };
    case "auth0user":
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case "userLoading": {
      return {
        ...state,
        userLoading: true,
      };
    }
    case "updateUserFields": {
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    }
    case "userLoaded": {
      return {
        ...state,
        userLoading: false,
        hasOrganization: state.user.organizationId,
        user: { ...state.user, ...action.payload },
      };
    }
    case "loading": {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case "userUpdate": {
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        reload: !state.reload,
      };
    }
    case "userCreate": {
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        isAuthenticated: false,
        reload: !state.reload,
        isCreatingNewOwner: true,
      };
    }
    case "reload": {
      return {
        ...state,
        reload: !state.reload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const UserProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const { logout, user, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();

  const userInitialState = {
    hasOrganization: false,
    isAuthenticated: false,
    isLoading: false,
    auth0Loading: false,
    userLoading: false,
    error: "",
    user: {},
    reload: false,
    isCreatingNewOwner: false,
  };

  const [state, dispatch] = useReducer(userReducer, userInitialState);

  //General loading
  useEffect(() => {
    dispatch({
      type: "loading",
      payload: state.userLoading || state.auth0Loading,
    });
  }, [state.userLoading, state.auth0Loading]);

  useEffect(() => {
    if (state.isCreatingNewOwner) {
      logout();
    }


    dispatch({ type: "auth0authentication", payload: isAuthenticated });
    //Added hitalento internal data - reload if auth changes ie ( user is logged in )
    if (isAuthenticated) {
      dispatch({ type: "userLoading" });
      const getUser = async () => {
        try {
          //Set the token!
          const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          });

          // Dispatch the token to the user state
          dispatch({ type: "updateUserFields", payload: { token: token } });

          // Decode the token
          const decodedToken = JSON.parse(atob(token.split('.')[1]));
          // Get the permissions included in the token
          const userPermissions = decodedToken.permissions || [];
          // Dispatch the permissions to the user state
          dispatch({ type: "updateUserFields", payload: { permissions: userPermissions } });

          // Set the token in the axios header
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          const { success, data } = await getMe();
          if (success) {
            dispatch({ type: "userLoaded", payload: data });
            //set user language
            i18n.changeLanguage(data.language);
          } else {
            dispatch({ type: "error", payload: data });
          }
        } catch (err) {
          dispatch({ type: "error", payload: err });
        }
      };

      getUser();
    }

    state.isCreatingNewOwner = false;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, getAccessTokenSilently, state.isCreatingNewOwner]);

  useEffect(() => {
    dispatch({ type: "auth0error", payload: error });
  }, [error]);

  useEffect(() => {
    dispatch({ type: "auth0loading", payload: isLoading });
  }, [isLoading]);

  useEffect(() => {
    dispatch({ type: "auth0user", payload: user });
  }, [user]);


  return (
    <DispatchUserContext.Provider value={dispatch}>
      <StateUserContext.Provider value={state}>
        {children}
      </StateUserContext.Provider>
    </DispatchUserContext.Provider>
  );
};
