import styled from "styled-components/macro";

export const SuperadminWrapper = styled.div`
display: flex; 
flex-direction: column;
width: 100%; 
`

export const SuperadminTopBar = styled.div`
height: 70px; 
background-color: #f3f3f5; 
box-shadow: 0 3px 6px rgba(24, 31, 56, 0.15);
display: flex; 
align-items: center;
justify-content: flex-end;
padding-right: 25px; 
padding-top: 5px; 
padding-bottom: 5px; 
min-height: 70px; 
`
