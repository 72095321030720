import styled from "styled-components/macro";
import { ResetButton } from "../../Button";

export const StyledSingleFileContainer = styled.div`
  width: 100%;

  position: relative;
  display: flex;
  flex-direction: column;

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  ${(props) =>
    props.disabled &&
    `    -webkit-filter: grayscale(100%);
    -moz-filter:    grayscale(100%);
    -ms-filter:     grayscale(100%);
    -o-filter:      grayscale(100%);
    filter: gray;`}

  background: ${(props) =>
    props.darkBackground ? props.theme.colors.lgrey : "transparent"};

${props => props.attachment ? `
padding: 0; 
border: none;
margin-bottom: 0;
display: flex; 
align-items: center; 
width: fit-content; 
margin-right: 5px;
` : null};
  & > input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 0;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  ${props => props.page && `
  height: 75px; 
  border-radius: 5px; 
      align-items: center;
    justify-content: center;
    width: 100%;
    padding: 36px;
}
  input {
    width: 100% !important; 
  }
  `}
  ${props => props.uploadBtn && `
& > input {
      width: 140px;
    height: 44px;
}
&::hover {
  border: 1px solid #FF385C; 
}
`}
${props => props.applicant && `
input {
  width: 100%; 
}
`}
`;

export const StyledButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer; 
  & > p.error-text {
    color: ${(props) => props.theme.colors.red};
  }
  ${props => props.attachment ? `
  align-items: flex-start;
  width: 15px; 
  ` : null}
  ${props => props.page ? `
  width: 100% !important;
  ` : null}
  ${props => props.applicant && `
  border: 1px solid rgba(24, 31, 56, 0.15);
  border-radius: 5px; 
  `}
  ${props => props.largeButton && `
  border: 2px dashed rgb(239, 239, 239);
  `}
`;

export const StyledProgressContainer = styled.div`
  /* flex-direction: row; */
  border: 2px dashed rgb(239, 239, 239);
  border-radius: 5px;
  padding: 26px;
`;

export const StyledValueContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-content: center; 
  border-radius: 5px;
  margin-bottom: 10px;
  border: 2px dashed rgb(239, 239, 239);
  padding: 20px;

  & > a {
    width: 100%;
    /* background-color: red; */
  }

  & > img {
    max-height: 60px;
    object-fit: contain;
    max-width: calc(100% - 40px);
    flex: 0 1 auto;
  }
  & > button {
    margin-left: 10px;
  }
  ${props => props.attachment && `
  background-color: rgba(24, 31, 56, 0.06); 
  border-radius: 11px; 
      padding: 2px 8px;
    padding-right: 12px;
  a {
    font-size: 12px; 
    font-weight: 500;
    color: #444A5E; 
  }
  `}
  ${props => props.uploadBtn && `
      width: fit-content;
    background-color: #F3F3F5;
    padding: 4px 12px;
    border-radius: 20px;
    display: flex;
    border: 1px solid rgba(24,31,56,0.15);
    margin-top: 12px;
    padding-right: 20px;
    a {
    font-size: 12px;
    color: rgba(24,31,56,0.6) !important;
    }
  `}
        ${props => props.headerImg && `
        padding: 0;
    margin: 0;
    width: 100% !important;
        border: none; 
    background-color: transparent; 
    height: 250px;
    & > img {
          height: 100%;
    width: 100%;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
    }
    `}
    ${props => props.applicant && `
    justify-content: flex-start; 
    `}
`;

export const SmallButton = styled(ResetButton)`
  padding: 10px 18px;
  border-radius: 60px;
  background-color: ${(props) =>
    props.useStyleVars
      ? "var(--primary-background-color)"
      : props.theme.colors.red};
  color: ${(props) => (props.useStyleVars ? "var(--primary-color)" : "#fff")};
  display: flex;
  font-size: ${(props) => props.theme.fonts.defaultSize};
  align-items: center;

  svg {
    margin-left: 6px;
    * {
      fill: ${(props) =>
    props.useStyleVars ? "var(--primary-color)" : "#fff"} !important;
    }
  }

  ${props => props.transparentButton && `
  border-radius: 10px; 
  border: 1px solid rgba(24, 31, 56, 0.15); 
  background-color: transparent; 
  color: rgba(24, 31, 56, 0.6); 
  padding: 8px 16px; 
  margin-right: auto; 
  margin-top: 10px; 
  `}
`;
