/**
 * returns true if string is a valid url ( http optional )
 * @param {string} url
 * @param {boolean} nullable - returns true if null or empty string
 * @returns {boolean}
 */
export const testUrl = (value, nullable) => {
  return (
    // eslint-disable-next-line no-useless-escape
    /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._%~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
      value
    ) ||
    // eslint-disable-next-line no-useless-escape
    /\(?(?:(http|https|ftp):\/\/)?(?:((?:[^\W\s]|\.|-|[:]{1})+)@{1})?((?:www.)?(?:[^\W\s]|\.|-)+[\.][^\W\s]{2,4}|localhost(?=\/)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::(\d*))?([\/]?[^\s\?]*[\/]{1})*(?:\/?([^\s\n\?\[\]\{\}\#]*(?:(?=\.)){1}|[^\s\n\?\[\]\{\}\.\#]*)?([\.]{1}[^\s\?\#]*)?)?(?:\?{1}([^\s\n\#\[\]]*))?([\#][^\s\n]*)?\)?/g.test(
      value
    ) ||
    (nullable && !value)
  );
};

/**
 * Validation for "organization", "position", "site" and "page" titles
 * Exclude som special characters
 * @param {string} str to test
 * @returns {boolean} true if title is valid!
 */
export const titleValidation = (str) => {
  // const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  // eslint-disable-next-line no-useless-escape
  const specialChars = /[`$%^*_+=\[\]{};'"|<>~]/;
  return !specialChars.test(str); //Title is valid if it contains no specialChars
};
