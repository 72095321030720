import styled from 'styled-components/macro';
import { StyledIcon } from '../Icon';
import plus from '../Icon/attachment_plus.svg';
import cross from '../Icon/cross.svg';

const setBackgroundColor = (props) => {
  if (props.useStyleVars) return 'var(--primary-background-color)';
  if (props.customBackgroundColor) return props.customBackgroundColor;
  if (props.transparent) return 'transparent';
  if (props.disabled) return props.theme.colors.mgrey;
  if (props.white) return '#fff';
  if (props.blue) return props.theme.colors.blue;
  if (props.red) return props.theme.colors.red;
  if (props.primaryColor || props.primary)
    return props.theme.colors.primary;
  if (props.secondary) return props.theme.colors.lgreytwo;
  return props.theme.colors.lgreytwo;
};

export const ResetButton = styled.button`
  background: none;
  border: 0;
  color: inherit;
  cursor: ${(props) => {
    return props.disabled ? 'not-allowed' : 'pointer';
  }};
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;

  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  :visited,
  :active,
  :focus {
    outline: none;
  }

  //disabled icon
  ${(props) =>
    props.disabled &&
    `
  ${StyledIcon} {
    svg , svg * {
      fill : ${props.theme.colors.mgrey} !important;
    }
  }
  `}
`;

export const RemoveButton = styled(ResetButton)`
  position: absolute;
  padding: 0px 0px;
  width: ${(props) => props.width || '36px'};
  height: ${(props) => props.height || '36px'};
  top: 0px;
  right: 0px;
  &:after,
  &:before {
    content: '';
    background: ${(props) => props.theme.colors.mgrey};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 10px;
    height: 1px;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export const DropdownButton = styled(ResetButton)`
  background: transparent;
  padding: 10px;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  text-transform: uppercase;
  /* display: flex; */
  > div:last-child {
    margin: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export const FileButton = styled.div`
  > label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 18px 30px;
    border-radius: 100px;
    cursor: pointer;
    white-space: nowrap;
    margin-bottom: 14px;
    color: ${(props) => props.theme.colors.dgrey};
    background-color: ${(props) => props.theme.colors.lgreytwo};
    > input {
      display: none;
    }
  }
`;

export const Button = styled(ResetButton)`
  //Reset Button
  display: flex;
  justify-content: center;
  align-items: center;
  text-align:left;

  ${props => props.page && `
  border: 1px solid rgba(24, 31, 56, 0.15);
  border-radius: 5px !important;
  height: 40px; 
  width: 40px; 
  display: flex;
  align-items: center; 
  justify-content: center;
  margin: 15px 0px 10px 0px;
  `}

  ${(props) => (props.nowrap ? 'white-space: nowrap;' : '')}

  * { pointer-events: none;}

  ${(props) => (props.noflex ? 'display:block;' : '')}}

  text-transform : ${(props) =>
    props.uppercase ? 'uppercase' : 'unset'} ;

  color: ${(props) => {
    if (props.useStyleVars) return 'var(--primary-color)';
    if (props.transparent && props.disabled) return props.theme.colors.mgrey;
    if (props.faded) return props.theme.colors.mgrey;
    if (props.buttonColorDark) return props.theme.colors.dgrey;
    if (props.disabled) return 'grey';
    if (props.highlight) return props.theme.colors.highlight;
    if (props.transparent) return props.theme.colors.dgrey;
    if (props.red) return '#fff';
    return props.theme.colors.primaryButtonColor;
  }};

  & > * {
    color: ${(props) => {
    if (props.faded) return props.theme.colors.mgrey;
    if (props.buttonColorDark) return props.theme.colors.dgrey;
    if (props.disabled) return 'grey';
    if (props.highlight) return props.theme.colors.highlight;
    if (props.transparent) return props.theme.colors.dgrey;
    return props.theme.colors.primaryButtonColor;
  }};
  }

  background-color: ${(props) => setBackgroundColor(props)};
  font-family: ${(props) => props.theme.fonts.fontFamily};
  font-size: ${(props) => {
    if (props.micro) return '9px';
    if (props.primary) return '18px';
    if (props.secondary) return props.theme.fonts.buttonStandardSize;
    return props.theme.fonts.buttonStandardSize;
  }};

  font-weight: ${(props) => {
    if (props.bold) return '600';
    return '400';
  }};
  padding: ${(props) => {
    if (props.micro) return '7px';
    if (props.primary) return '20px 40px';
    if (props.secondary) return '18px 30px';
    if (props.tertiary) return '10px 16px';
    if (props.urlLike) return '0';
    return '10px 16px';
  }};
  border-radius: ${(props) => (props.primary ? '15px' : '100px')};
  width: ${(props) => (props.expand ? '100%' : 'auto')};
  /* border: 1px solid transparent; */
  /* transition: 0.5s ease-in-out; */
  & > * {
    font-size: inherit;
  }
  &:hover {
    box-shadow: 0px 0px 12px ${(props) => setBackgroundColor(props)};
    transition: 0.25s ease-in-out;
  }
  &:active {
    background-color: ${(props) =>
    props.urlLike ? 'none' : props.theme.colors.dgrey};
  }
  & + button {
    margin-left: 10px;
  }
  &:disabled {
    filter:  brightness(60%)  contrast(0.8);
    /* opacity: 0.8; */
  }
`;

export const StopButton = styled(ResetButton)`
  width: 22px;
  height: 22px;
  position: relative;
  border-radius: 22px;
  ${props => props.ml ? `margin-left: ${props.ml}px !important;` : null};
  ${props => props.attachment && `
    &::before {
    content: '';
    position: absolute;
    height: 7.5px;
    width: 7.5px;
    left: 4px; 
    top: 0; 
     background-image: url(${plus});
  }
  height: 7.5px;
  width: 7.5px;
  `}
    ${props => props.uploadBtn && `
    &::before {
    content: '';
    position: absolute;
    height: 7px;
    width: 7px;
    left: 8px; 
    background-size: contain;
    top: 0; 
     background-image: url(${cross});
  }
  height: 7px;
  width: 7px;
  padding-right: 8px;
  `}
`;

export const PrimaryButton = styled.div`
  width: ${(props) => props.fullWidth ? '100%' : 'fit-content'};
 ${props => props.justifyContent ? `justify-content: ${props.justifyContent};` : null}; 
  border: 1px solid ${props => props.theme.colors.mgrey}; 
  padding: 10px 25px; 
  border-radius: 10px; 
  align-items: center;
  position: relative; 
  cursor: ${(props) => {
    return props.disabled ? 'default' : 'pointer';
  }};
  display: flex;
  flex-direction: row-reverse;  
 ${props => props.mr ? `margin-right: ${props.mr}px;` : null}; 
 ${props => props.ml ? `margin-left: ${props.ml}px;` : null}; 
 ${props => props.mt ? `margin-top: ${props.mt}px !important;` : null};
 ${props => props.mb ? `margin-bottom: ${props.mb}px;` : null};
 ${props => props.alignRight ? `margin-left: auto;` : null}; 
 ${props => props.alignCenter && `margin: 0 auto`};
  color: ${props => props.theme.colors.faceliftBlueLight}; 
  text-transform : ${(props) => props.uppercase ? 'uppercase' : 'unset'};
  ${props => props.fixed && `
  position: fixed; left: 0;
  z-index: 9999; 
  `}
  ${props => props.white ? `
  background-color: white;
  transition: .5s ease; 
  &:hover {
    background-color: transparent; 
    transition: .5s ease; 
    border: 1px solid #FF385C;
    color: #FF385C;  
    .Icon__StyledIcon-sc-1wxjmha-0 {
     filter: brightness(0) saturate(100%) invert(33%) sepia(57%) saturate(2635%) hue-rotate(328deg) brightness(100%) contrast(103%);
     transition: all .3s ease;
    }
  }
  .Icon__StyledIcon-sc-1wxjmha-0 {
    transition: all .5s ease;
  }
  ` : null};
  ${props => props.red ? `
  background-color: #FF385C;
  border: 1px solid #FF385C;
  color: white; 
  font-weight: 500; 
  transition: .5s ease;
  &:hover {
    background-color: transparent; 
    transition: .5s ease;
    color: #FF385C;
    .Icon__StyledIcon-sc-1wxjmha-0 {
     filter: brightness(0) saturate(100%) invert(33%) sepia(57%) saturate(2635%) hue-rotate(328deg) brightness(100%) contrast(103%);
     transition: .5s ease;
    }
  }
  .Icon__StyledIcon-sc-1wxjmha-0 {
    filter: brightness(0) invert(1);
    transition: .5s ease;
  }
  ` : null};
  ${props => props.red && props.disabled ? `
  background-color: #d5d5d5;
  border: 1px solid #d5d5d5;
  &:hover {
    background-color: #d5d5d5;
    .Icon__StyledIcon-sc-1wxjmha-0 {
      filter: brightness(0) invert(1);
    }
  }
  ` : null};
  ${props => props.transparent ? `
  border: none;
  transition: .5s ease;
  position: relative; 
  &::before {
    content: '';
    position: absolute; 
    bottom: 0; 
    left: 25px; 
    height: 1px;
    width: 0;
    background-color: #FF385C;
    transition: .5s ease;
  }

  .Icon__StyledIcon-sc-1wxjmha-0 {
    transition: .2s ease;
  }
  ` : null}
  ${props => props.transparent && !props.disabled ? `
  &:hover {
    color: #FF385C;
    transition: .5s ease;
    &::before {
      width: 35%; 
      transition: .5s ease;
    }
    .Icon__StyledIcon-sc-1wxjmha-0 {
     filter: brightness(0) saturate(100%) invert(33%) sepia(57%) saturate(2635%) hue-rotate(328deg) brightness(100%) contrast(103%);
     transition: .2s ease;
    }
  }
  ` : null}
  ${props => props.largeButton ? `
  margin: 20px 0;
  border-radius: 20px;
  width: unset !important;
  background-color: var(--primary-background-color);
  color: var(--primary-color)!important;

  ${StyledIcon} {
    margin-right: 0px;
    margin-left: 12px;
    svg , svg * {
      fill : var(--primary-color)!important;
      opacity: 1;
    }
  }
  ` : null}
  ${props => props.center ? 'margin: 0 auto;' : null};
  ${props => props.mtSM ? `margin-top:${props.mtSM}px;` : null}; 
  ${props => props.icon ? (`
    padding-left: 28px; 
  &::before {
    content: ''; 
    position: absolute; 
    height: 100%; 
    width: 100%; 
    background-image: url('${props.icon}'); 
    background-repeat: no-repeat;
    top: calc(50% - 6px); 
    left: 25px; 
    filter: brightness(0) saturate(100%) invert(34%) sepia(12%) saturate(801%) hue-rotate(190deg) brightness(90%) contrast(84%);
  }
  `) : null}
  ${props => props.noPadding && `padding: 0 0 5px 0`}; 
  ${props => props.pl ? `padding-left: ${props.pl}px` : null};
  @media (max-width: 992px) {
    ${props => props.hideOnMobile && `display: none;`}
  }
  @media (min-width: 1200px) {
    ${props => props.mtLG ? `margin-top: ${props.mtLG}px !important` : null}
  }
  ${props => props.applicant && `
  width: 100%; 
  justify-content: flex-end; 
  color: rgba(0, 0, 0, 0.5);
  padding: 12px 20px; 
  `}
  `

export const ProdButton = styled(ResetButton)`
  padding: 10px 30px;
  background-color: ${(props) => props.theme.colors.red};
  color: white;
  &:disabled {
    background-color: ${(props) => props.theme.colors.lgrey};
    color: ${(props) => props.theme.colors.mgrey};
  }
  font-weight: bold;
  border-radius: 34px;
`;


export const SwitchText = styled.div`
font-size: 12px; 
${props => props.fontWeight ? `font-weight: ${props.fontWeight};` : 'font-weight: 400;'};  
${props => props.white ? `color: ${props.theme.colors.white}` : `color: ${props.theme.colors.slateGray}`}; 
margin-left: 8px;
${props => props.mr ? `margin-right: ${props.mr}px;` : null};
`

export const Switch = styled.div`
cursor: pointer; 
height: 14px; 
width: 24px; 
background-color: ${props => props.theme.colors.red}; 
border-radius: 8px; 
position: relative; 
${props => props.ml ? `margin-left: ${props.ml};` : null}; 
${props => props.mr ? `margin-right: ${props.mr};` : null}; 
&::before {
  content: '';
height: 10px; 
width: 10px; 
position: absolute;
background-color: ${props => props.theme.colors.white}; 
right: 2.5px;
top: calc(50% - 5px); 
border-radius: 8px; 
transition: .3s ease;

}
${props => !props.active ? (`
background-color: #444A5E;
transition: .5s ease; 
   &::before {
right: 11.5px;
transition: .3s ease;
}
`) : null};
`

export const EditButton = styled.div`
display: flex;
flex-direction: row-reverse;
font-size: 12px; 
color: ${props => props.theme.colors.slateGray}; 
font-weight: 500; 
${props => props.mr ? `margin-right: ${props.mr}px;` : null};
`