import styled from "styled-components/macro";

export const SuperadminWrapper = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%; 
height: 6px;
background-color: red;
background-color: ${(props) => props.theme.colors.highlight};
z-index: 999999999999999999;
`