import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { StatePositionContext } from "../../../../../context/PositionContext";
import { ApplicationContext } from "../../../ApplicationContext";
import { SkillPicker } from "../../../../../../components";
import { Col, Grid, Row } from "../../../../../../components/Grid";
import { InputLabel } from "../../../../../../components/Input/Input";

const CompetenciesAndLanguages = () => {
  //LANGUAGE
  const { t } = useTranslation();

  //POSTION STATE
  const { position } = useContext(StatePositionContext);

  //APPLICATION STATE
  const { state, dispatch } = useContext(ApplicationContext);
  return (
    <Grid>
      <Row>
        <Col size={24}>
          <InputLabel required={true} boldLabel={true}>{t("application_form.skillpicker_label", "Rate yourself on a scale of 1-5 (where 5 is high level)")}</InputLabel>
        </Col>
      </Row>
      <Row>
        {(position?.competencies && position?.competencies.length > 0) ? (
          <Col size={12} break="lg">
            <SkillPicker
              required
              pickerValues={position.competencies}
              pickerName="competencies"
              pickerMessageNoValues={t("application_form.competencies_none")}
              pickerLabels={["1", "2", "3", "4", "5"]}
              values={state.application}
              persistAllApplicationValues={(e) => {
                dispatch({
                  type: "update_field",
                  payload: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                });
              }}
            />
          </Col>
        ) : null}
        {(position?.languages && position?.languages.length > 0) ? (
          <Col size={12} break="lg">
            <SkillPicker
              mt={(position?.competencies && position?.competencies.length > 0) ? 20 : 0}
              required
              pickerValues={position.languages}
              pickerName="languages"
              pickerMessageNoValues={t("application_form.languages_none")}
              pickerLabels={["1", "2", "3", "4", "5"]}
              values={state.application}
              persistAllApplicationValues={(e) => {
                dispatch({
                  type: "update_field",
                  payload: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                });
              }}
            />
          </Col>
        ) : null}
      </Row>
    </Grid>
  );
};

export default CompetenciesAndLanguages;
